import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";

export class ScanDetails extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 50,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
      },
      loader: false,
    };
    this.columns = [
      {
        dataIndex: "customer",
        title: "User Name",
        render: (text, record) => {
          return <>{record?.customer?.full_name || "-"}</>;
        },
      },
      {
        dataIndex: "customer",
        title: "Mobile No.",
        render: (text, record) => {
          return <>{record?.customer?.phone_number || "-"}</>;
        },
      },
      {
        dataIndex: "customer",
        title: "Email ID",
        render: (text, record) => {
          return <>{record?.customer?.email_address || "-"}</>;
        },
      },
      {
        dataIndex: "updatedAt",
        title: "Scanned On",
        render: (text, record) => {
          if (text) {
            let date = new Date(text).dateToDDMMYYYY(new Date(text));
            return date || "-";
          } else {
            return "-";
          }
        },
      },
    ];
  }
  // ----------------------------------------------------------------
  componentDidMount() {
    this.fetchList();
  }
  fetchList(params) {
    this.setState({ loader: true });
    let payload = {
      ...this.state.search,
      ...params,
      package_id: this.props.urlparams.package_id,
    };
    this.props.api
      .apiGetAccessPackageById(payload)
      .then((response) => {
        console.log({ response });

        this.setState({
          loader: false,
          data: response.data,
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
      });
  }
  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList()
    );
  }
  getTitle() {
    return `B2C Package Configuration`;
  }
  getBreadCums() {
    let packagename = this.props.urlparams.package_name;
    if (this.props.urlparams.package_id) {
      return `B2C Package Configuration / Scan Details (${packagename})`;
    } else {
      return `B2C Package Configuration / Scan Details`;
    }
  }
  render() {
    let { data = [], rowcount, loader } = this.state;
    let { columns } = this;
    return (
      <>
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <LayoutWrapper
          title={this.getTitle()}
          back={this.getBreadCums()}
          backlink="/businesstoconsumerconfig"
        >
          <div className="row scaldetails_table">
            <div className="col-12">
              {!!data.length && (
                <AppTable
                  data={data}
                  columns={columns}
                  onChange={(...arg) => this.onPageChange(...arg)}
                  total={rowcount}
                  reorder={true}
                  deletable={false}
                  editable={false}
                  viewable={false}
                  targetType="tap"
                  isresponsive={false}
                ></AppTable>
              )}
              {data.length == 0 && <div className="empty_layout"></div>}
            </div>
          </div>
        </LayoutWrapper>
      </>
    );
  }
}

export default connect(ScanDetails);
