export default {

    getAllPackages: {
        url: "/api/v2/package",
        method: "get",
    },
    getPackageById: {
        url: "/api/v2/package/open/{package_id}",
        method: "get",
    },
    createPackage: {
        url: "/api/v2/package",
        method: "post",
    },
    updatePackage: {
        url: "/api/v2/package/{package_id}",
        method: "put",
    },
    getPackageQRCode: {
        url: "/api/v2/qrcode/{package_id}",
        method: "post",
    },
    getAccessPackageById: {
        url: "/api/v2/package/access/{package_id}",
        method: "get",
    },
}