import { Component } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LayoutWrapper from "./LayoutWrapper";
import PackageSearch from "./PackageSearch";

export class B2CPackageConfig extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("B2CPackageConfig");
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 50,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
      },
      loader: false,
    };
    this.columns = [
      {
        dataIndex: "package_name",
        title: "Name",
      },
      {
        dataIndex: "scanned",
        title: "# of Scans",
        render: (text, record) => (
          <a
            href="#"
            className="anchorTag"
            onClick={() => this.getScanDetails(record)}
          >
            {record?.scanned?.length}
          </a>
        ),
      },
      {
        dataIndex: "session_id",
        title: "# of Sessions",
        render: (text, record) => (
          <a
            href="#"
            className="anchorTag"
            onClick={() => this.getSessionDetails(record)}
          >
            {record?.session_id?.length}
          </a>
        ),
      },
      {
        dataIndex: "createdAt",
        title: "Created Date",
        render: (text, record) => {
          if (text) {
            let date = new Date(text).dateToDDMMYYYY(new Date(text));
            return date || "-";
          } else {
            return "-";
          }
        },
      },
      {
        dataIndex: "updatedAt",
        title: "Last Modified Date",
        render: (text, record) => {
          if (text) {
            let date = new Date(text).dateToDDMMYYYY(new Date(text));
            return date;
          } else {
            return "-";
          }
        },
      },
      {
        dataIndex: "status",
        title: "Status",
        render: (text, record) => {
          return (
            <span
              className={`badge light border-0 ${
                record.status?.toTitleCase() === "status"
                  ? "text-primary"
                  : record.status == "DISABLE"
                  ? "badge-danger"
                  : "badge-success"
              } `}
            >
              {record.status?.toTitleCase()}
            </span>
          );
        },
      },
    ];
    this.role = localStorage.getItem("currentAdminRole");
  }

  componentDidMount() {
    this.fetchList();
  }
  getScanDetails(record) {
    this.props.navigate(
      `/businesstoconsumerconfig/scandetails?package_id=${record?.package_id}&package_name=${record?.package_name}`
    );
  }

  getSessionDetails(record) {
    this.props.navigate(
      `/businesstoconsumerconfig/sessions?package_id=${record?.package_id}&package_name=${record?.package_name}`
    );
  }

  addPackageInfo() {
    this.props.navigate(`/businesstoconsumerconfig/configpage`);
  }

  fetchList = (params) => {
    this.setState({ loader: true });
    let payload = {
      ...this.state.search,
      ...params,
    };
    this.props.api.apiGetPackages(payload).then((response) => {
      this.setState({
        loader: false,
        data: response.data,
      });
    });
  };

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList()
    );
  }

  deleteAction(record) {
    confirm(`Do you really want to delete these school?`).promise.then(() => {
      let payload = {
        is_deleted: true,
      };
      this.setState({
        loader: true,
      });
      this.props.api
        .apiUpdatePackage(payload, { package_id: record.package_id })
        .then((res) => {
          success("Package Deleted Successfully");
          this.fetchList();
          this.setState({
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
        });
    });
  }
  downloadAction(record) {
    this.setState({
      loader: true,
    });
    this.props.api
      .apiGetPackageQRCode({ package_id: record?.package_id })
      .then(async (res) => {
        if (res) {
          this.setState({
            loader: false,
          });

          let response = await res;
          this.downloadBase64Image(
            response?.data?.qrcode,
            `qr_code_${response?.data?.qrcode_id}`
          );
          success("QR Downloaded Successfully");
        }
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
      });
  }
  downloadBase64Image(base64, fileName) {
    const link = document.createElement("a");
    link.href = base64;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  editAction(v) {
    console.log("edit: ", v);
    this.props.navigate(
      `/businesstoconsumerconfig/configpage?package_id=${v.package_id}&package_name=${v.package_name}`
    );
  }

  viewAction(v) {
    console.log("view: ", v);
    this.props.navigate(
      `/businesstoconsumerconfig/configpage?package_id=${v.package_id}&package_name=${v.package_name}&readonly=true`
    );
  }

  onSearch(data) {
    console.log("onSearch data: ", data);
    let searchPayload = {};
    if (Object.keys(data).length === 0) {
      searchPayload = { ...this.state.search };
    } else {
      searchPayload = { ...this.state.search, ...data };
    }
    this.fetchList(searchPayload);
  }

  render() {
    let { data = [], rowcount, loader } = this.state;
    let { columns } = this;

    return (
      <>
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <ToastContainer position="top-right" />

        <LayoutWrapper
          title="B2C Package Configuration"
          back="B2C Package Configuration"
        >
          <div>
            <div className="row">
              <div className="col"></div>
              {localStorage.getItem("currentAdminRole") == "SUPER_ADMIN" && (
                <div className="mb-4">
                  <Link
                    className="btn add-btn"
                    onClick={() => this.addPackageInfo()}
                  >
                    <i className="fa fa-plus"></i>
                    Add Package
                  </Link>
                </div>
              )}
            </div>
            <div className="row mb-3">
              <div className="col">
                <PackageSearch
                  inactive={() => {
                    console.log(this.props.inactive);
                    return this.props.inactive;
                  }}
                  onSearch={(...arg) => this.onSearch(...arg)}
                />
              </div>
            </div>
            <div className="row schoolmanagement">
              <div className="col-12">
                {!!data.length && (
                  <AppTable
                    data={data}
                    columns={columns}
                    onNext={() => this.onNext()}
                    onPrev={() => this.onPrev()}
                    onChange={(...arg) => this.onPageChange(...arg)}
                    total={rowcount}
                    reorder={true}
                    deleteAction={(v) => this.deleteAction(v)}
                    editAction={(v) => this.editAction(v)}
                    viewAction={(v) => this.viewAction(v)}
                    downloadable={true}
                    downloadAction={(v) => this.downloadAction(v)}
                    targetType="tap"
                    isresponsive={false}
                  ></AppTable>
                )}
                {data.length == 0 && <div className="empty_layout"></div>}
              </div>
            </div>
          </div>
        </LayoutWrapper>
      </>
    );
  }
}
export default connect(B2CPackageConfig);
