import React, { Component } from "react";
export class PackageSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      fields: [
        {
          name: "package_name",
          label: "Search by Name",
          type: "text",
        },
      ],
    };
  }

  onSearch() {
    let data = this.refs.form.form.current.getData();
    // console.log("onSearch formData", data);
    data = Object.filter(data, (val) => val);
    // console.log("data on search", data);
    this.props.onSearch && this.props.onSearch(data);
  }

  clearTextData = () => {
    this.setState({
      fields: this.state.fields.map((field) => ({ ...field, value: "" })),
    });
    let data = this.refs.form.form.current.getData();
    data = Object.filter(data, (val) => "");
    this.props.onSearch && this.props.onSearch(data);
  };

  render() {
    let { fields } = this.state;

    return (
      <AppForm className="row filter-row" ref="form">
        {fields.map((field, i) => (
          <div className="col-sm-6 col-md-9" key={i}>
            <AppInputFocus {...field}></AppInputFocus>
          </div>
        ))}
        <div className="col-sm-6 col-md-3">
          <button
            className="btn btn-success btn-block w-100"
            onClick={() => this.onSearch()}
          >
            Search
          </button>
        </div>
        <span className="clear-all" onClick={() => this.clearTextData()}>
          Clear Filter
        </span>
      </AppForm>
    );
  }
}

export default connect(PackageSearch);
