export default {
    apiGetPackages(data) {

        let payload = {
            ...data,
        };

        return this.request(this.api().getAllPackages, payload)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0,
                };
            })
            .get();
    },

    apiGetPackageById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().getPackageById)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data,
                    total: response.count || 0,
                };
            })
            .get();
    },
    apiAddPackage(data) {
        let payload = {
            ...data,
            recommended_class: data?.recommended_class?.splitBy(","),
            category: data?.category?.splitBy(","),
            session_id: data?.session_id?.splitBy(","),
        };

        return this.request(this.api().createPackage, payload)
            .exec()
            .log()
            .mapresults((response) => {
                return response.data;
            })
            .get();
    },
    apiUpdatePackage(data, urldata) {

        let payload = {
            ...data,
            recommended_class: data?.recommended_class?.splitBy(","),
            category: data?.category?.splitBy(","),
            session_id: data?.session_id?.splitBy(","),
        };
        return this.request(this.api().updatePackage, payload)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data,
                };
            })
            .get();
    },
    apiGetPackageQRCode(urldata = {}) {

        return this.request(this.api().getPackageQRCode, {}, urldata, urldata)
            .urltransform(urldata)
            // .responsetype("blob")
            .exec()
            .log()
            .get();
    },

    apiGetPackageById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().getPackageById)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data,
                    total: response.count || 0,
                };
            })
            .get();
    },

    apiGetAccessPackageById(urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().getAccessPackageById)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination || 0,
                };
            })
            .get();
    },
}