import devapis from "./devapis";
import stagingapis from "./stagingapis";
import prodapis from "./prodapis";

export const config = {
  dev: {
    base: "http://localhost:3000,http://localhost:3001,https://stagelogin.cognospace.in/",
    api_base: "https://devdevapi.cognospace.in",
    static_token: "",
    ...devapis,
    "open/login": {
      base: "http://localhost:3000,http://localhost:3001,https://stagelogin.cognospace.in/",
      api_base: "https://devdevapi.cognospace.in",
      ...devapis,
    },
  },
  staging: {
    base: "https://stagelogin.cognospace.in/,https://stagelogin.cognospace.in/",
    api_base: "https://stagedevapi.cognospace.in",
    static_token: "",
    ...stagingapis,

    "open/login": {
      base: "https://stagelogin.cognospace.in/,https://stagelogin.cognospace.in/",
      api_base: "https://stagedevapi.cognospace.in",
      ...stagingapis,
    },
  },
  production: {
    base: "https://login.cognospace.in",
    api_base: "https://devapi.cognospace.in",
    static_token: "",
    ...stagingapis,

    "open/login": {
      base: "https://login.cognospace.in",
      api_base: "https://devapi.cognospace.in",
      ...stagingapis,
    },
  },
};

export default config;
