import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { useEffect, useState, useRef } from "react";

const AppUploadFile = (props) => {
  const inputRef = useRef(null);
  const [filevalue, setFilevalue] = useState(null);
  const [fileobjvalue, setFileObjValue] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  useEffect(() => {
    if (uploadStatus !== "removed") {
      if (props.defaultValue instanceof Array) {
        setFilevalue(props.defaultValue[0]?.attachment_id);
      } else {
        setFilevalue(props.defaultValue);
      }
    }
  }, [props.defaultValue]);
  const handleChange = (...args) => {
    let [info] = args;
    // To write code to accept only required formats
    // if (props?.accept) {
    //   if (!props?.accept.includes(info.file.type)) {
    //     return;
    //   }
    // }
    setFileObjValue(info.file);

    console.log({ fileReturnType: props.fileReturnType });
    console.log({ fileType: props.fileType });
    console.log({ file: info.file }, args);
    setUploadStatus(info.file.status);
    if (info.file.status !== "removed" && info.file.status !== "done") {
      let loaded = loader("Uploading file please wait....");

      const resolve = (fileresponse) => {
        let attachmentId = fileresponse.data.attachment_id;
        let fileUrl = fileresponse.data.url;
        setFilevalue(attachmentId);
        console.log({ file: attachmentId, url: fileUrl });
        if (props?.fileReturnType && props.fileReturnType === "url") {
          inputRef.current.invalue = fileUrl;
        } else {
          inputRef.current.invalue = attachmentId;
        }
        loaded.close();
        props.onFileUploaded && props.onFileUploaded(attachmentId);
      };
      //
      if (props?.uploadType && props.uploadType === "public") {
        app.api
          .apiFileUploaderPublic({
            file: info.file.originFileObj,
            file_type: props.fileType || "school_document",
          })
          .then((fileresponse) => {
            resolve(fileresponse);
          })
          .catch(() => loaded.close());
      } else {
        app.api
          .apiFileUploader({
            file: info.file.originFileObj,
            file_type: props.fileType || "school_document",
          })
          .then((fileresponse) => {
            resolve(fileresponse);
          })
          .catch(() => loaded.close());
      }
      props.onChange && props.onChange(info.file);
    }
    if (info.file.status === "removed") {
      const handleRemove = () => {
        loaded.close();
        inputRef.current.invalue = "";
        setFilevalue("");
        props.onChange && props.onChange("");
        props.onFileRemoved && props.onFileRemoved();
      };
      let loaded = loader("Deleting file please wait....");
      app.api
        .apiFileRemover(
          {
            file_type: props.fileType || "school_document",
            file_id: filevalue,
          },
          {
            attachmentId: filevalue,
          }
        )
        .then(handleRemove)
        .catch(handleRemove);
    }

    console.log({ file: info.file });
  };
  let { multiple = false, maxCount = 1 } = props;

  const downloadFile = function (fileId) {
    let authToken = window.store.getState().root.auth;
    // console.log({ authToken: authToken, fileId });
    return (
      app.api
        .request(app.apis().fileDownloader)
        .urltransform({ attachmentId: fileId }).url +
      "?auth=" +
      authToken
    );
  };
  const attrs = {
    ...Object.except(props, ["className", "name", "onChange"]),
    onChange: props?.onChange || handleChange,
    multiple,
    maxCount,
    ...(filevalue
      ? {
          fileList: filevalue
            ? [
                {
                  uid: "1",
                  name: props.filename || "File",
                  status: "done",
                  url: downloadFile(filevalue),
                  percent: 100,
                },
              ]
            : [],
        }
      : {}),
  };
  return (
    <Upload {...attrs} customRequest={({ onSuccess }) => onSuccess("ok")}>
      <input
        ref={inputRef}
        type="hidden"
        className="filevalue_container"
        name={props.name}
        value={filevalue}
      ></input>
      <Button icon={<UploadOutlined />}>Upload File</Button>
    </Upload>
  );
};
export default connect(AppUploadFile);
